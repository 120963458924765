import * as React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Header from '../components/Header102/Header';
import Footer from '../components/Footer6/Footer';
import PrivacySection from '../components/PrivacySection/PrivacySection';

const PrivacyPage = ({ data, location }) => {
  const companyName = data.site.siteMetadata.companyName;
  return (
    <div>
      <Header
        title="団欒処 和菜屋"
        logoImage="/wazai/logo.png"
        navItems={[
          {
            id: 1,
            name: '事業案内',
            link: '/about',
            dropdown: false,
          },
          {
            id: 2,
            name: 'こだわり',
            link: '/founder',
            dropdown: false,
          },
          {
            id: 3,
            name: 'アクセス',
            link: '/access',
            dropdown: false,
          },
          {
            id: 4,
            name: '求人情報',
            link: '/recruit',
            dropdown: false,
          },
        ]}
      />
      <PrivacySection companyName={companyName} />
      <Footer
        items={[
          {
            categoriesname: '事業内容',
            categories: [
              { name: '会社概要', link: '/about' },
              { name: '創業者の想い', link: '/founder' },
              { name: 'アクセス', link: '/access' },
              { name: 'デイサービス和才屋', link: '/dayservice' },
            ],
          },
          {
            categoriesname: '会社歴史',
            categories: [
              { name: '創業者の想い', link: '/founder' },
              { name: '沿革', link: '/history' },
            ],
          },
          {
            categoriesname: 'キャリア',
            categories: [
              { name: 'プライバシーポリシー', link: '/privacy' },
              { name: '採用', link: '/recruit' },
            ],
          },
        ]}
        logo="/wazai/logo.png"
        logoAltText="和菜屋"
        copyrightText="団らん処 和菜屋"
        logoLinkText="和菜屋"
        facebookUrl="https://www.facebook.com/wazaiya.kokura/"
        xUrl="https://twitter.com/wazaiya_sozai"
        instagramUrl="https://www.instagram.com/wazaiya.sozai/"
      />
    </div>
  );
};

export default PrivacyPage;

export const Head = () => (
  <Seo
    title={'プライバシーポリシー'}
    description={`プライバシーポリシーのページです。`}
  />
);

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        companyName
      }
    }
  }
`;
